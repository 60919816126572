import React from 'react'

const Footer = props => {

    return(
        <div id="copyright">
             <p>&copy; Kevin Lam</p>
        </div>
    )
}

export default Footer